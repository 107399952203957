<template>
    <div>
      <b-row>
        <b-col lg="12" md="12" sm="12" class="heading-title">
  
       
              <h4>Create Child Venue</h4>
                  
              <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item to="/venues">Parent Venue</b-breadcrumb-item>
            <b-breadcrumb-item @click="go_to_child()"
            >Child Venue</b-breadcrumb-item
          >
            <b-breadcrumb-item active>Add <i class="mdi mdi-arrow-right"></i> </b-breadcrumb-item>
        </b-breadcrumb>
                  
  
         
         
        </b-col>
        <!-- <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col> -->
      </b-row>
      
      <br />
      <!-- Tabs with card integration -->
      
        <b-tabs v-model="tabIndex" content-class="mt-3" fill  class="custom-navbar" >
            <div class="flex justify-content-between">
        <div class=" mb-2">
        <b-button @click="tabIndex--" v-if="tabIndex!=0" class="btn-venuecolor btn-sm">Previous</b-button>
      </div>
      <!-- <div>
        <b-alert show size="sm">Please Select Time</b-alert>
      </div> -->
      <div class="mt-2 mb-2">
        
          <!-- <b-button @click="tabIndex++" v-if="tabIndex!=6" class="btn-venuecolor  ml-2 btn-sm" :disabled="tabIndex>1">Skip</b-button> -->
          <b-button @click="save_and_next" v-if="tabIndex!=6" class="btn-venuecolor ml-2 btn-sm" >Save & Next</b-button>
         
          <b-button @click="save_and_next" v-if="tabIndex==6" class="btn-venuecolor btn-sm">Finish</b-button>
       
       <!--  <div class="text-muted">Current Tab: {{ tabIndex }}</div> -->
      </div>
     
      </div>
          <b-tab  >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Overview
            </template>
            <Overview ref="OverView" @name="getName" @parent_name="pname"/>
          </b-tab>
          <b-tab >
            <template #title>
              <i class="mdi mdi-stackoverflow"   ></i> Time Shift
            </template>

            <TimeShift ref="Timeshift" :chld_venue_id='child_venue_id'/>
          </b-tab>
          <b-tab lazy >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Price Shift
            </template>

            <Price_shift ref="Priceshift" :chld_venue_id='child_venue_id' />
          </b-tab>
          <b-tab   >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Capacity
            </template>
            <MoreDeatl ref="MoreDeatl" :chld_venue_id='child_venue_id' />
          </b-tab>
          <b-tab    >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Gallery View
            </template>
            <Gallery ref="gallery" :chld_venue_id='child_venue_id' />
          </b-tab>
          <b-tab    >
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Amentities
            </template>
            <Amentities ref="amentities" :chld_venue_id='child_venue_id' />
            
          </b-tab>
          <b-tab   lazy>
            <template #title>
              <i class="mdi mdi-stackoverflow"></i> Add-On
            </template>
            <AddOns ref="addons" :chld_venue_id='child_venue_id' />
          </b-tab>
        </b-tabs>
  
      <!-- Control buttons-->
      <b-modal
		id="parent_venue"
		:no-close-on-backdrop="true"
		size="sm"
		:hide-footer="true"
		:hide-header="true"
		:centered="true"
		>
			<h5 class="text-center my-4">Child Venue created Successfully!</h5>
			<div style="text-align: center">
				<b-button variant="outline-primary" @click="createSetting()"
				>Settings</b-button
				>
				<br />
				<br />
				<b-button variant="outline-secondary" @click="skipStep()"
				>Skip</b-button
				>
			</div>
		</b-modal>
      
    </div>
  </template>
  
  <script>
  //import TimeShift from '../../newVenue/new_venue_setting.vue'
  import Price_shift from './price_shift.vue'
  import TimeShift from './Time_shift.vue'
  import Overview from './overview.vue'
  import MoreDeatl from './More_det.vue'
  import Gallery from './Gallery.vue'
  import Amentities from './Amentities.vue'
  import AddOns from './addOns.vue'

    export default {
      data() {
        return {
          tabIndex: 0,
          child_venue_id:'',
          parent_name:'',
          status:true,
        }
      },
      components: {
        Price_shift,
        TimeShift,
        Overview,
        MoreDeatl,
        Gallery,
        Amentities,
        AddOns,
      },
      methods:
      {
        save_and_next()
        {

          let obj;
          if(this.tabIndex==0)
          {
            
            obj = this.$refs.OverView.next_overview();
          }
          else if(this.tabIndex==1)
          {
            obj = this.$refs.Timeshift.newShiftUpdate()
            
          }
          else if(this.tabIndex==2)
          {
            obj=this.$refs.Priceshift.nextvalidation()
          }
          else if(this.tabIndex==3)
          {
            obj = this.$refs.MoreDeatl.next_moredetails()
          }
          else if(this.tabIndex==4)
          {
            obj =  this.$refs.gallery.next_gallery_view()
          }
          else if(this.tabIndex==5)
          {
            obj =  this.$refs.amentities.next_amenities()
            //this.$refs.addons.add_on_Listing()
          }
          else if(this.tabIndex==6)
          {
            obj =  this.$refs.addons.nextvalidation()
          }
          if(obj==1)
          {
            return this.tabIndex++;
          } 
          else if(obj==2)
          {
           
            this.$bvModal.show("parent_venue");
            this.$root.$refs.app.showToast(
                "success",
                "Child venue created successfully"
              );
           /*   const id = this.$route.params.id;
           this.$router.push("/venues/child-venue/" + id, () => {
              this.$root.$refs.app.showToast(
                "success",
                "Child venue created successfully"
              );
             
            }); */
          } 
          else if(obj==3)
          {
           
              this.$root.$refs.app.showToast(
                "danger",
                "Sorry. Error found"
              );
          }
          else
          {
            this.$root.$refs.app.showToast(
                "danger",
                "Check validation"
              );
          }
          
          
        },
        /* checkvalidation(num)
        {
          //return this.tabIndex > num ? false : true;
        }, */
        go_to_child() {
      const id = this.$route.params.id;
      this.$router.push("/venues/child-venue/" + id);
      },
      getName(value) {
      this.child_venue_id=value; // Raja Tamil
   },
    pname(value) {
      this.parent_name=value; // Raja Tamil
   },
   skipStep() {
				//this.$router.push("/venues");
         const id = this.$route.params.id;
           this.$router.push("/venues/child-venue/" + id );
			},
			createSetting() {
        const id = this.$route.params.id;
        const name = this.parent_name;
        var json =id+'&'+name;
				this.$router.push("/child_venue_setting/" + json);
			},
      
    },
    mounted()
    {
    //  this.checkvalidation(this.tabIndex);
    }
   /*  computed: {
    status: function() {
      return this.tabIndex < 6
    }
  } */
    }
  </script>
  <style>
  .custom-navbar .nav-link.active  {
        color: white !important;
        background-color: #499CE8 !important;
        border-bottom: 2px solid #DD1BFB !important;
        border-color: 0 !important;
        }
  </style>