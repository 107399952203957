<template>
	<div>
		
		<b-modal
		:no-close-on-backdrop="true"
		title="Create Add On"
		ok-title="Save"
		@ok="handleOkAddOn"
		@cancel="closeAddOn"
		id="add_on"
		>
			<b-form
			id="addon-input-group-1"
			@submit.stop.prevent="handleSubmitAddOn"
			>
			<b-form-group label-for="name-group" id="name-group" >
					<b-row class="py-3 align-items-center" v-if="!newCategory">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Category<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="8">
							<b-form-select
							v-model="$v.add_on.category_id.$model"
							:options="add_on_category_options"
							:state="validateStateAddOn('category_id')"
							placeholder="Enter Add on name"
							name="name-group"
							id="category_id"
							aria-describedby="url-1-live-feedback"
							></b-form-select>
							<b-form-invalid-feedback
							id="category_id"
							v-if="$v.add_on.category_id.$error"
							>
								<div v-if="!$v.add_on.category_id.required">
									Please select category
								</div>
							</b-form-invalid-feedback>
						</b-col>
						<b-col sm="1">
							<a class="btn btn-info btn-sm" v-if="!newCategory" @click="selectnew()"><i class="mdi mdi-plus"></i></a>
							
						</b-col>
					</b-row>

					<b-row class="py-3 align-items-center" v-if="newCategory">
						
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>New Category<span class="text-danger">*</span> </label
							>
						</b-col>
						
						<b-col sm="8">
							<b-form-input
							v-model="$v.c_add.add_category_addon.$model"
							:state="validateStateAddOnC('add_category_addon')"
							placeholder="Enter Category"
							name="name-group"
							id="add_category_addon"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="name"
							v-if="$v.c_add.add_category_addon.$error"
							>
								<div v-if="!$v.c_add.add_category_addon.required">
									Please enter category
								</div>
							</b-form-invalid-feedback>
						</b-col>
						<b-col sm="1">
							<a class="btn btn-danger btn-sm " v-if="newCategory" @click="selectRemove()"><i class="mdi mdi-delete"></i></a>
							
						</b-col>
						
					</b-row>

				</b-form-group>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label
							class="fw-medium mb-0"
							for="lname2"
							style="font-size: 13px"
							>Add On Name<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="$v.add_on.name.$model"
							:state="validateStateAddOn('name')"
							placeholder="Enter Add on name"
							name="name-group"
							id="name"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="name"
							v-if="$v.add_on.name.$error"
							>
								<div v-if="!$v.add_on.name.required">
									Please enter Add on name
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
				
                <!-- <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Category<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-select
							v-model="$v.add_on.category_id.$model"
							:options="add_on_category_options"
							:state="validateStateAddOn('category_id')"
							placeholder="Enter Add on name"
							name="name-group"
							id="category_id"
							aria-describedby="url-1-live-feedback"
							></b-form-select>
							<b-form-invalid-feedback
							id="category_id"
							v-if="$v.add_on.category_id.$error"
							>
								<div v-if="!$v.add_on.category_id.required">
									Please select category
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group> -->
				
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Price<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="$v.add_on.price.$model"
							:state="validateStateAddOn('price')"
							placeholder="Enter price"
							name="name-group"
							id="price"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="price"
							v-if="$v.add_on.price.$error"
							>
								<div v-if="!$v.add_on.price.required">
									Please enter price
								</div>
								<div v-if="!$v.add_on.price.numeric">
									Please enter valid price
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>More details:</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="add_on.more_details"
							placeholder="Enter more details"
							name="name-group"
							id="more_details"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
						</b-col>
					</b-row>
				</b-form-group>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Photo/Video:</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-file
							accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
							:file-name-formatter="image_videos"
							v-model="add_on.image_video"
							aria-describedby="url-1-live-feedback"
							></b-form-file>
						</b-col>
					</b-row>
				</b-form-group>
			</b-form>
		</b-modal>
		<b-modal
		:no-close-on-backdrop="true"
		title="Create Category"
		ok-title="Save"
		@ok="handleOkAddOnC"
		@cancel="closeAddOnC"
		id="add_on_c"
		>
			<b-form
			id="addon-input-group-1"
			@submit.stop.prevent="handleSubmitAddOnC"
			>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Category<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="$v.c_add.add_category_addon.$model"
							:state="validateStateAddOnC('add_category_addon')"
							placeholder="Enter Category"
							name="name-group"
							id="add_category_addon"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="name"
							v-if="$v.c_add.add_category_addon.$error"
							>
								<div v-if="!$v.c_add.add_category_addon.required">
									Please enter category
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
			</b-form>
		</b-modal>
		<b-modal
		:no-close-on-backdrop="true"
		hide-header-close
		title="Edit Add On"
		ok-title="Save"
		@ok="handleOkAddOnEdit"
		@cancel="closeAddOnEdit"
		id="add_on_edit"
		>
			<b-form
			id="addon-input-group-1"
			@submit.stop.prevent="handleSubmitAddOnEdit"
			>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label
							class="fw-medium mb-0"
							for="lname2"
							style="font-size: 13px"
							>Add On Name<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="$v.add_on_edit.name.$model"
							:state="validateStateAddOnEdit('name')"
							placeholder="Enter Add on name"
							name="name-group"
							id="name"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="name"
							v-if="$v.add_on_edit.name.$error"
							>
								<div v-if="!$v.add_on_edit.name.required">
									Please enter Add on name
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
				
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Category<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-select
							v-model="$v.add_on_edit.category.$model"
							:options="add_on_category_options"
							:state="validateStateAddOnEdit('category')"
							placeholder="Enter Add on name"
							name="name-group"
							id="category"
							aria-describedby="url-1-live-feedback"
							></b-form-select>
							<b-form-invalid-feedback
							id="category"
							v-if="$v.add_on_edit.category.$error"
							>
								<div v-if="!$v.add_on_edit.category.required">
									Please select category
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
				
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Price<span class="text-danger">*</span> :</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="$v.add_on_edit.price.$model"
							:state="validateStateAddOnEdit('price')"
							placeholder="Enter price"
							name="name-group"
							id="price"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
							<b-form-invalid-feedback
							id="price"
							v-if="$v.add_on_edit.price.$error"
							>
								<div v-if="!$v.add_on_edit.price.required">
									Please enter price
								</div>
								<div v-if="!$v.add_on_edit.price.numeric">
									Please enter valid price
								</div>
							</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</b-form-group>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>More details:</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-input
							v-model="add_on_edit.more_details"
							placeholder="Enter more details"
							name="name-group"
							id="more_details"
							aria-describedby="url-1-live-feedback"
							></b-form-input>
						</b-col>
					</b-row>
				</b-form-group>
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Photo/Video:</label
							>
						</b-col>
						<b-col sm="9">
							<b-form-file
							accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
							:file-name-formatter="image_videos"
							v-model="add_on_edit.image_video"
							aria-describedby="url-1-live-feedback"
							></b-form-file>
						</b-col>
					</b-row>
				</b-form-group>
                <b-form-group
				label-for="name-group"
				id="name-group"
				v-if="add_on_edit.file_addon !== ''"
                >
					<b-row class="py-3 align-items-center">
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Download:</label
							>
						</b-col>
						<b-col sm="9">
							<span class="font-weight-light"
							>Click here to view your add on image/video.</span
							><br />
							<b-link :href="add_on_edit.file_addon" target="_blank">
								<span class="text-primary" style="font-size: 24px">
									<i class="mdi mdi-eye"></i> </span
								></b-link>
						</b-col>
					</b-row>
				</b-form-group>
			</b-form>
		</b-modal>
		<b-modal
		:no-close-on-backdrop="true"
		hide-header-close
		ok-only
		title="View Add On"
		ok-title="Close"
		@ok="closeAddOnView"
		id="add_on_view"
		>
			<b-form-group label-for="name-group" id="name-group">
                <b-row class="py-3 align-items-center">
					<b-col sm="3" class="text-left">
						<label
						class="fw-medium mb-0"
						for="lname2"
						style="font-size: 13px"
						>Add On Name:</label
						>
					</b-col>
					<b-col sm="9">
						<span> {{ add_on_edit.name }}</span>
					</b-col>
				</b-row>
			</b-form-group>
			
			<b-form-group label-for="name-group" id="name-group">
                <b-row class="py-3 align-items-center">
					<b-col sm="3" class="text-left">
						<label class="fw-medium mb-0" for="lname2">Category:</label>
					</b-col>
					<b-col sm="9">
						<span> {{ add_on_edit.category_name }}</span>
					</b-col>
				</b-row>
			</b-form-group>
			
			<b-form-group label-for="name-group" id="name-group">
                <b-row class="py-3 align-items-center">
					<b-col sm="3" class="text-left">
						<label class="fw-medium mb-0" for="lname2">Price:</label>
					</b-col>
					<b-col sm="9">
						
						<span v-html="currency_check(add_on_edit.price)"></span>
					</b-col>
				</b-row>
			</b-form-group>
			<b-form-group
			label-for="name-group"
			id="name-group"
			v-if="
			add_on_edit.more_details !== '' ||
			add_on_edit.more_details !== null
			"
			>
                <b-row class="py-3 align-items-center">
					<b-col sm="3" class="text-left">
						<label class="fw-medium mb-0" for="lname2"
						>More details:</label
						>
					</b-col>
					<b-col sm="9">
						<span> {{ add_on_edit.more_details }}</span>
					</b-col>
				</b-row>
			</b-form-group>
			<b-form-group
			label-for="name-group"
			id="name-group"
			v-if="add_on_edit.file_addon !== ''"
			>
                <b-row class="py-3 align-items-center">
					<b-col sm="3" class="text-left">
						<label class="fw-medium mb-0" for="lname2">Download:</label>
					</b-col>
					<b-col sm="9">
						<span class="font-weight-light"
						>Click here to view your add on image/video.</span
						><br />
						<b-link :href="add_on_edit.file_addon" target="_blank">
							<span class="text-primary" style="font-size: 24px">
								<i class="mdi mdi-eye"></i> </span
							></b-link>
					</b-col>
				</b-row>
			</b-form-group>
		</b-modal>
		<b-card class="mb-4 w-100">
			
			<div class="venue-heading">
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="6"
					md="12"
					sm="12"
					>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="6"
					md="12"
					sm="12"
					>
						<div class="w-100 d-flex justify-content-end">
							
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="venue-heading">
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="6"
					md="5"
					sm="12"
					>
						<!-- <div class="p-1">
							<b-button size="sm"
							id="button_width1"
							variant="primary"
							@click="CreateAddOnC()"
							>
								Add Category</b-button
							>
						</div> -->
						<div class="p-1">
							<b-button size="sm"
							id="button_width1"
							variant="primary"
							@click="CreateAddOn()"
							>
								Add on
								data</b-button
							>
						</div>
						<div class="p-1">
							<b-button size="sm"
							id="button_width"
							@click="export_add_on()"
							variant="primary"
							>
								Export</b-button
							>
						</div>
						
						
					</b-col>
					<b-col
					
					cols="12"
					lg="3"
					md="6"
					sm="12"
					>
						<div class="p-1">
							<multiselect
							v-model="addon_selected"
							:options="addon_option"
							placeholder="Select Add On"
							>
							</multiselect>
						</div>
					</b-col>
					<b-col
					
					cols="12"
					lg="3"
					md="6"
					sm="12"
					>
						<div class="w-100 p-1">
							<div>
								<multiselect
								v-model="category_selected"
								:options="category_option_add_on"
								placeholder="Select Category"
								>
								</multiselect>
							</div>
						</div>
					</b-col>
					
					
					<b-col
					
					cols="12"
					lg="6"
					md="6"
					sm="12"
					>
						<div class="p-2 float-left">
							<b-form-input
							v-model="search"
							id="search"
							type="text"
							placeholder="Search here"
							v-on:keyup="key_search()"
							></b-form-input>
						</div>
						
					</b-col>
					<b-col
					
					cols="12"
					lg="6"
					md="6"
					sm="12"
					>	
						<div class="p-2 float-right">
							
							<b-form-select
							
							id="select_perpage"
							v-model="pageSize"
							:options="options"
							@change="handlePageSizeChange($event)"
							></b-form-select>
							
							<b-button variant="primary" size="sm" @click="applyFilter()" class="ml-2">
								Apply</b-button
							>
							<b-button variant="primary" size="sm" @click="resetFilter()" class="ml-2">
								Reset</b-button
							>
						</div>
					</b-col>
					
					
					
				</b-row>
			</div>
			
			<div>
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="6"
					sm="6"
					>
						<div
						class="
						w-100
						justify-content-sm-center
						justify-content-xl-start
						justify-content-md-start
						"
						>
							
						</div>
					</b-col>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="8"
					md="12"
					sm="12"
					>
						
					</b-col>
				</b-row>
			</div>
			<br />
			<div>
                <b-table
				:busy="load"
				:fields="fields"
				:items="add_on_data"
				responsive
				hover
				bordered
				show-empty
				empty-text="No Data Available"
				class="text-center"
                >
					<template #table-busy>
						<b-skeleton-table
						v-if="load"
						:rows="10"
						:columns="6"
						:hide-header="true"
						:table-props="{ bordered: true, striped: true }"
						></b-skeleton-table>
					</template>
					<template  #cell(price)="data">
						<span v-html="currency_check(data.item.price)"></span>
					</template>
					<template #cell(publish_status)="data">
						<b-form-checkbox
						name="check-button"
						switch
						v-on:change="statusChange(data.item.id,$event)"
						:checked="data.item.publish_status == 1"
						>
						</b-form-checkbox>
					</template>


					<template #cell(view)="data">
						<span>
							<i
							@click="viewAddon(data.item.id)"
							class="fas fa-eye fa-lg text-dark"
							:id="data.item.id"
							></i>
						</span>
					</template>
					<template #cell(edit)="data">
						<span>
							<i
							@click="updateAddon(data.item.id)"
							class="fas fa-edit fa-lg text-dark"
							:id="data.item.id"
							></i>
						</span>
					</template>
				</b-table>
                <b-row align-v="center">
					<b-col cols="4">
						<span
						>Showing <b>{{ totalRows }}</b> out of
							<b>{{ totalData }}</b> entries.</span
						>
					</b-col>
					<b-col cols="8">
						<b-pagination
						v-model="page"
						:total-rows="count"
						:per-page="pageSize"
						prev-text="Prev"
						next-text="Next"
						align="right"
						@change="handlePageChange"
						></b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
	import axios from "axios";
	import { validationMixin } from "vuelidate";
	import Multiselect from "vue-multiselect";
	import {
		required,
		numeric,
	} from "vuelidate/lib/validators";
	export default {
		props:['chld_venue_id'],
		components: {Multiselect},
		mixins: [validationMixin],
		data(){
			return {
                add_on: {
                    name: "",
                    category_id: null,
                    price: "",
                    more_details: "",
                    image_video: [],
				},
                add_on_category_options: [{ value: null, text: "Please select category" }],
				add_on_edit: {
					add_on_id: "",
					name: "",
					category: null,
					price: "",
					more_details: "",
					file_addon: "",
					image_video: [],
					category_name: "",
				},
				
				fields: [
				//  'index',
				{
					key: "name",
					label: "Add On Name",
					sortable: false,
				},
				{
					key: "category",
					label: "Category",
					sortable: false,
				},
				{
					key: "price",
					label: "Price",
					sortable: false,
				},
				{
					key: "publish_status",
					label: "Publish Status",
				},
				{
					key: "view",
					label: "View",
				},
				{
					key: "edit",
					label: "Edit",
				},
				],
				item: [],
				addOns: [],
				value: "",
				addon_selected: "",
				category_selected: "",
				newCategory:false,
				add_on_data: [],
				add_onCounts: 0,
				options: [10, 20, 30, 50],
				
				pageSize: 10,
				show: false,
				load: true,
				filter: "",
				search: "",
				CurrentPage: 1,
				sortBy: "created_at",
				
				page: 1,
				count: 0,
				totalData: "",
				totalRows: "",
				
				PerPage: "50",
				
				addon_option: [],
				category_option: [],
				category_option_add_on: [],
				c_add: {
					add_category_addon: "",
				},
			}
		},
        validations: {
			
			add_on: {
				name: {
					required,
				},
				category_id: {
					required,
				},
				price: {
					required,
					numeric,
				},
			},
			add_on_edit: {
				name: {
					required,
				},
				category: {
					required,
				},
				price: {
					required,
					numeric,
				},
			},
			c_add: {
				add_category_addon: {
					required,
				},
			},
		},
		methods:
		{
            export_add_on() {
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post(
				"/api/export_addon_pdf",
				{
					params,
					search_keyword: keyword,
					addon_name: this.addon_selected,
					category: this.category_selected,
					child_venue_id: this.chld_venue_id,
				},
				{
					responseType: "blob",
				}
				)
				.then((resp) => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "add_on_lists.pdf");
					document.body.appendChild(link);
					link.click();
				});
			},
			
			updateAddon(id) {
				var parent_id = this.$route.params.id;
				var attachment;
				this.add_on_category_list();
				axios
				.post("api/edit_addon", {
					addon_id: id,
					parent_id: parent_id,
					child_id: this.chld_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_edit.add_on_id = resp.data.add_on[0].add_on_id;
							this.add_on_edit.name = resp.data.add_on[0].add_on_name;
							this.add_on_edit.more_details = resp.data.add_on[0].more_details;
							this.add_on_edit.price = resp.data.add_on[0].price;
							attachment = resp.data.add_on[0].attachment;
							this.add_on_edit.category =
							resp.data.add_on[0].add_on_category_id;
							if (attachment !== null) {
								this.add_on_edit.file_addon =
								resp.data.path + JSON.parse(attachment)[0];
							}
							//console.log(this.add_on_edit.file_addon);
							this.$bvModal.show("add_on_edit");
							//console.log(this.add_on_edit);
						}
					}
				});
			},
			viewAddon(id) {
				var parent_id = this.$route.params.id;
				var attachment;
				this.add_on_category_list();
				axios
				.post("api/edit_addon", {
					addon_id: id,
					parent_id: parent_id,
					child_id: this.chld_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_edit.add_on_id = resp.data.add_on[0].add_on_id;
							this.add_on_edit.name = resp.data.add_on[0].add_on_name;
							this.add_on_edit.more_details = resp.data.add_on[0].more_details;
							
							this.add_on_edit.price = resp.data.add_on[0].price;
							attachment = resp.data.add_on[0].attachment;
							this.add_on_edit.category =
							resp.data.add_on[0].add_on_category_id;
							if (attachment !== null) {
								this.add_on_edit.file_addon =
								resp.data.path + JSON.parse(attachment)[0];
							}
							for (var i = 0; i < this.add_on_category_options.length; i++) {
								if (
								this.add_on_edit.category ===
								this.add_on_category_options[i].value
								) {
									this.add_on_edit.category_name =
									this.add_on_category_options[i].text;
								}
							}
							this.$bvModal.show("add_on_view");
							//console.log(this.add_on_edit);
						}
					}
				});
			},
			closeAddOnView() {
				this.add_on_edit.add_on_id = "";
				this.add_on_edit.name = "";
				this.add_on_edit.more_details = "";
				this.add_on_edit.price = "";
				this.add_on_edit.file_addon = "";
				this.add_on_edit.category_name = "";
				this.add_on_edit.category = null;
				this.add_on_edit.image_video = [];
				this.$bvModal.hide("add_on_edit");
			},
			handleOkAddOnEdit(bvModalEvent) {
				//
				bvModalEvent.preventDefault();
				
				this.$v.add_on_edit.$touch();
				if (this.$v.add_on_edit.$anyError) {
					return;
					} else {
					this.handleSubmitAddOnEdit();
				}
			},
			handleSubmitAddOnEdit() {
				var formDataAdd = new FormData();
				formDataAdd.append("add_on_id", this.add_on_edit.add_on_id);
				formDataAdd.append("child_venue_id", this.chld_venue_id);
				formDataAdd.append("category_id", this.add_on_edit.category);
				
				formDataAdd.append("add_on_name", this.add_on_edit.name);
				formDataAdd.append("price", this.add_on_edit.price);
				formDataAdd.append("more_details", this.add_on_edit.more_details);
				if (this.add_on_edit.image_video.length != 0) {
					formDataAdd.append("photo_video[]", this.add_on_edit.image_video);
				}
				
				axios
				.post("/api/update_addon", formDataAdd, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							// this.$bvModal.hide("add_on_edit");
							this.closeAddOnEdit();
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message[0]);
						}
					}
				});
			},
			closeAddOnEdit() {
				this.add_on_listing_data();
				this.add_on_edit.add_on_id = "";
				this.add_on_edit.name = "";
				this.add_on_edit.more_details = "";
				this.add_on_edit.price = "";
				this.add_on_edit.file_addon = "";
				this.add_on_edit.category_name = "";
				this.add_on_edit.category = null;
				this.add_on_edit.image_video = [];
				this.$v.add_on_edit.$reset();
				this.$bvModal.hide("add_on_edit");
			},
			CreateAddOn() {
				this.$v.add_on.$reset();
				this.add_on.category_id = null;
				this.add_on.image_video = [];
				this.$bvModal.show("add_on");
				this.add_on_category_list();
			},
			handleOkAddOn(bvModalEvent) {
				//
				bvModalEvent.preventDefault();
				
				this.$v.add_on.$touch();
				if (this.$v.add_on.$anyError) {
					return;
					} else {
					this.handleSubmitAddOn();
				}
			},
			handleSubmitAddOn() {
				const tab_type = "add_on";
				const tabs = JSON.stringify(
				'{"overview":1, "shift": 1, "more_detais":1, "gallery":1 , "amenities":1, "add_on":1}'
				);
				var formDataAdd = new FormData();
				formDataAdd.append("tab_type", tab_type);
				formDataAdd.append("tabs", tabs);
				formDataAdd.append("child_venue_id", this.chld_venue_id);
				formDataAdd.append("category_id", this.add_on.category_id);
				formDataAdd.append("new_category_name", this.c_add.add_category_addon);
				formDataAdd.append("add_on_name", this.add_on.name);
				formDataAdd.append("price", this.add_on.price);
				formDataAdd.append("more_details", this.add_on.more_details);
				if (this.add_on.image_video.length != 0) {
					formDataAdd.append("photo_video[]", this.add_on.image_video);
				}
				// if (this.add_on.image_video.length != 0) {
				//   for (var i = 0; i < this.add_on.image_video.length; i++) {
				//     formDataAdd.append("photo_video[]", this.add_on.image_video[i]);
				//   }
				// }
				
				axios
				.post("/api/add_child_venue", formDataAdd, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.closeAddOn();
							this.add_on_listing_data();
							this.add_on_Listing();
							this.c_add.add_category_addon="";
							this.newCategory=false;
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message[0]);
						}
					}
				});
			},
			closeAddOn() {
				this.$nextTick(() => {
					this.$bvModal.hide("add_on");
					this.add_on.name = "";
					
					this.add_on.category_id = null;
					(this.add_on.price = ""), (this.add_on.more_details = "");
					//  this.add_on.image_video = [];
					this.$v.add_on.$reset();
				});
			},
			add_on_category_list() {
				axios
				.post("/api/get_categories", { child_venue_id: this.chld_venue_id })
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_category_options = [
							{ value: null, text: "Please select category" },
							];
							for (var i = 0; i < resp.data.category.length; i++) {
								this.add_on_category_options.push({
									value: resp.data.category[i].id,
									text: resp.data.category[i].name,
								});
							}
						}
					}
				});
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.add_on_listing_data();
			},
			handlePageSizeChange(event) {
				// console.log(event.target.value);
				this.pageSize = event;
				this.load = true;
				this.page = 1;
				this.add_on_listing_data();
			},
			sortChanged(e) {
				this.sortBy = e.sortBy;
				this.load = true;
				this.add_on_listing_data();
			},
			add_on_listing_data() {
				this.load = true;
				this.show = true;
				// this.venue = [];
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post("/api/fetch_add_on", {
					params,
					search_keyword: keyword,
					addon_name: this.addon_selected,
					category: this.category_selected,
					child_venue_id: this.chld_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						this.add_on_data = [];
						if (resp.data.status) {
							this.count = this.totalData = resp.data.total_records;
							this.totalnewCategoryRows = resp.data.datalist.length;
							
							for (var i = 0; i < resp.data.datalist.length; i++) {
								this.add_on_data.push({
									id: resp.data.datalist[i].add_on_id,
									name: resp.data.datalist[i].add_on_name,
									price: resp.data.datalist[i].price,
									category: resp.data.datalist[i].has_category.name,
									publish_status: resp.data.datalist[i].publish_status,
								});
							}
							
							// console.log(this.add_on_data);
							this.show = false;
							this.load = false;
							} else {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.datalist.length;
							
							this.show = false;
							this.load = false;
						}
					}
				});
			},
			getRequestParams(page, pageSize, sortBy = "") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
			key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			resetFilter() {
				(this.addon_selected = ""),
				(this.category_selected = ""),
				(this.search = "");
				this.add_on_listing_data();
			},
			add_on_Listing() {
                this.add_on_category_list();
				axios
				.post("/api/get_add_on_name", { child_venue_id: this.chld_venue_id })
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.addon_option = resp.data.add_name;
							
							this.category_option_add_on = resp.data.category;
						}
					}
				});
               
			},
			CreateAddOnC() {
				this.$bvModal.show("add_on_c");
			},
			
			handleOkAddOnC(bvModalEvent) {
				bvModalEvent.preventDefault();
				
				this.$v.c_add.$touch();
				if (this.$v.c_add.$anyError) {
					return;
					} else {
					this.handleSubmitAddOnC();
				}
			},
			handleSubmitAddOnC() {
				axios
				.post("/api/add_category", {
					category_name: this.c_add.add_category_addon,
					child_venue_id: this.chld_venue_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.closeAddOnC();
							this.add_on_Listing();
						}
						else {
							this.$root.$refs.app.showToast("danger", resp.data.message[0]);
						}
					}
				});
			},
			closeAddOnC() {
				this.$nextTick(() => {
					this.$bvModal.hide("add_on_c");
					this.c_add.add_category_addon = "";
					this.$v.c_add.$reset();
				});
			},
            validateStateAddOn(name) {
				const { $dirty, $error } = this.$v.add_on[name];
				return $dirty ? !$error : null;
			},
			validateStateAddOnEdit(name) {
				const { $dirty, $error } = this.$v.add_on_edit[name];
				return $dirty ? !$error : null;
			},
			validateStateAddOnC(add_category_addon) {
				const { $dirty, $error } = this.$v.c_add[add_category_addon];
				return $dirty ? !$error : null;
			},
			image_videos(files) {
				return files.length === 1
				? files[0].name
				: `${files.length} files are selected`;
			},
			nextvalidation()
			{
				if(this.chld_venue_id)
				{
					return 2;
				}
				else
				{
					return 3;
				}
				
			},
			/* statusChange(add_id) {
      axios.post("/api/add_on_publish_status", { id: add_id }).then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.$root.$refs.app.showToast("success", resp.data.message);
            //this.venuelListing();
          } else {
            this.$root.$refs.app.showToast("danger", resp.data.message);
          }
        }
      });
    }, */
	statusChange(add_id,evt) {
				if (evt==true){
					this.addOns.push(add_id)
				} else {
					this.addOns.splice(this.addOns.indexOf(add_id), 1)
				}
      axios.post("/api/add_on_publish_status", { id: add_id ,child_id: this.chld_venue_id,addOn:this.addOns }).then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.$root.$refs.app.showToast("success", resp.data.message);
            //this.venuelListing();
          } else {
            this.$root.$refs.app.showToast("danger", resp.data.message);
          }
        }
      });
    },
	selectnew()
			{
			
				this.add_on.category_id="0";
				//amenities.ThCategory  amenities.NCategory
				this.newCategory=true;
			},
			selectRemove()
			{
				
				this.c_add.add_category_addon="";
				this.newCategory=false;
			},
			currency_check(amt) {
				return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
		},
        mounted() {
			
			this.add_on_Listing();
			this.add_on_category_list();
			this.add_on_listing_data();
			this.getProfile();
			
		},
	}
	
	
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
	
</style>