<template>
	<div>
        <div class="card">
			
            <div class="card-body">
				<b-row>
					<b-col cols="12" md="3" sm="3">
						
						<b-row>
							<b-col cols="12" md="12" sm="12">
								<select class="form-control" name="shift" id="shift" v-model="shift" @focus="shift_chage()"> 
									<option value="">Select Shift</option>
									
									<option v-for="(shifts ,s) in headers " :key="s" :value="changeShiftId(shifts.name)" class="Text-transforms">{{ shifts.name }} Shift</option>
									<!-- 	<option value="2">Afternoon Shift</option>
									<option value="3">Evening Shift</option> -->
								</select>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="12" md="2" sm="2">
						
						
						<b-row>
							<b-col cols="12" md="12" sm="12">
								
								<input type="date" class="form-control" id="inputEmail4" v-model="value1"
								:min='disabledAfterToday()' @change="autoDateChange()" >
							</b-col>
						</b-row>
						
					</b-col>
					<b-col cols="12" md="2" sm="2">
						<b-row>
							<b-col cols="12" md="12" sm="12">
								
								<input type="date" class="form-control" id="inputEmail4" v-model="value2"
								:default-value="new Date()"
								:min='dateformate(value1)' :disabled="value1==''">
							</b-col>
						</b-row> 
						<span v-if="value2" class="text-danger fs-8">
							{{ messaget }}
						</span>   
					</b-col>
					<b-col cols="12" md="3" sm="3">
						<b-row>
							<b-col cols="12" md="12" sm="12">
								<input type="number" class="form-control" v-model="allAmount" placeholder="Amount ">
							</b-col>
						</b-row>   
					</b-col>
					<b-col cols="12" md="2" sm="2">
						<button class="btn btn-success btn-block" type="button" @click="savetheamount()">
							
							<div class="spinner-border spinner-border-sm text-light" v-if="loader1" role="status">
								
							</div> Save</button>
					</b-col>
				</b-row>
			</div>
		</div>
		
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		props:['shifts'],
		data(){
			return {
				shift:"",
				value1:"",
				value2:"",
				allAmount:"",
				messaget:"",
				msg_show:false,
				loader1:false,
				child_venue_id:"",
				headers:[]
				
			}
		},
		methods:
		{
			disabledAfterToday:function() {
				var d = new Date(),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
			
			if (month.length < 2) 
			month = '0' + month;
			if (day.length < 2) 
			day = '0' + day;
			
			return [year, month, day].join('-');
	},
	date_formate:function(selectdate) {
		var d = new Date(selectdate),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();
		
		if (month.length < 2) 
		month = '0' + month;
		if (day.length < 2) 
		day = '0' + day;
		
		return [year, month, day].join('-');
	},
	dateformate:function(date)
	{
		/*  var myDate = new Date(new Date(date).getTime()+(1*24*60*60*1000));
			var d = new Date(myDate),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();
			
			if (month.length < 2) 
			month = '0' + month;
			if (day.length < 2) 
		day = '0' + day; */
		var addmonth=this.addMonths(new Date(date),18).toString()
		return this.date_formate(addmonth);
		
		// return [year, month, day].join('-');
	},
	todaydate:function()
	{
		var d = new Date(),
		day = d.getDate();
		
		if (day.length < 2) 
		day = '0' + day;
		
		return day;
	},
	savetheamount:function()
	{
		//this.child_venue_id = this.$route.params.id;
		
		if( this.allAmount=="" ||  this.shift=="")
		{
			this.loader1=false;
			this.$root.$refs.app.showToast("Error", "Please Fill Required field");
			return false;
		}
		this.loader1=true;
		axios.post("/api/all_amount_is_update",{amount: this.allAmount,shift: this.shift,fdate: this.value1,tdate:this.value2,vid:this.child_venue_id} ).then((resp) => {
			console.log(resp);
			this.loader1=false;
			this.$root.$refs.app.showToast("success", "Amount updated");
			this.$emit("reload-timeshift");
			this.allHeader();
			
		});
		
	},
	allHeader()
	{
		axios.post("/api/all_publish_header",{vid:this.child_venue_id} ).then((resp) => {
			this.headers=resp.data.data;
			
		});
	},
	shift_chage()
	{
		this.allHeader();
	},
	changeShiftId(names)
	{
		if(names=="Morning")
		{
			return 1;
		}
		else if(names=="Afternoon")
		{
			return 2;
		}
		else if(names=="Evening")
		{
			return 3;
		}
	},
	autoDateChange()
	{
		this.msg_show=true;
		var addmonth=this.addMonths(new Date(this.value1),18).toString()
		this.value2=this.date_formate(addmonth);
		
	},
	//addMonths(new Date(2016,1,29),12).toString()
	addMonths(date, months) {
		var d = date.getDate();
		date.setMonth(date.getMonth() + +months);
		if (date.getDate() != d) {
			date.setDate(0);
		}
		var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth()+1, 0);
		
		var Difference_In_Time = lastDayOfMonth.getTime() - date.getTime();
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
		
		this.messaget=months+" Month and "+ Math.round(Difference_In_Days) +' Day is enabled' ;
		
		return lastDayOfMonth;
	}
	
},
mounted()
{
	let child_id=localStorage.getItem('child_venue_id');
	this.child_venue_id = child_id ? child_id : this.$route.params.id;
	// console.log(this.$emit('load-todo'));
	//this.headers=this.$emit('load-todo');
	this.allHeader();
}
}
</script>

<style>
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	
	/* Firefox */
	input[type=number] {
	-moz-appearance: textfield;
	}
	
	input#inputEmail4 {
	display: inline-block;
	position: relative;
	}
	
	input[type="date"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
	}
	option.Text-transforms:first-letter {
    text-transform: uppercase !important;
	}
	.fs-8
	{
	font-size: 10px;
	}
	</style>			