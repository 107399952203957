<template>
  <div>
 
    <b-row>
              <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="6"
                md="12"
                sm="12"
              >
                <b-card class="mb-4 w-100" border-variant="primary">
                  <div class="text-center">
                    <h4 class="font-weight-light">
                      Banner
                    </h4>
                    <b-form-file
                      accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
                      multiple
                      :file-name-formatter="file_day_light"
                      v-model="files_day_light"
                    ></b-form-file>
                  </div>
                </b-card>
              </b-col>
              <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="6"
                md="12"
                sm="12"
              >
                <b-card class="mb-4 w-100">
                  <div class="text-center">
                    <h4 class="font-weight-light">
                      Photos
                    </h4>
                    <b-form-file
                      accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
                      multiple
                      :file-name-formatter="file_front_view"
                      v-model="files_front_view"
                    ></b-form-file>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="6"
                md="12"
                sm="12"
              >
                <b-card class="mb-4 w-100">
                  <div class="text-center">
                    <h4 class="font-weight-light">
                      Thumbnail
                    </h4>
                    <b-form-file
                      accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
                      multiple
                      :file-name-formatter="file_night_life"
                      v-model="files_night_life"
                    ></b-form-file>
                  </div>
                </b-card>
              </b-col>
             <b-col
                class="d-flex align-items-stretch"
                cols="12"
                lg="6"
                md="12"
                sm="12"
              >
                <b-card class="mb-4 w-100">
                  <div class="text-center">
                    <h4 class="font-weight-light">
                      Videos 
                    </h4>
                    <b-form-file
                      accept=".jpg, .png, .jpeg, .svg, .mp4, .mkv"
                      multiple
                      :file-name-formatter="file_back_view"
                      v-model="files_back_view"
                    ></b-form-file>
                  </div>
                </b-card>
              </b-col>
            </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
    props:['chld_venue_id'],
    data() {
        return {
            files_day_light: [],
            files_front_view: [],
            files_night_life: [],
            files_back_view: [],
            file_day_light:'',
            file_front_view:'',
            file_night_life:'',
            file_back_view:'',
        }
      },
      methods:
      {

        next_gallery_view() {
          if(this.chld_venue_id)
				{
        if (
          this.files_day_light.length != 0 ||
          this.files_night_life.length != 0 ||
          this.files_front_view.length != 0 ||
          this.files_back_view.length != 0
        ) {
          const tab_type = "gallery_view";
          var edit_status = false;
          const tabs = JSON.stringify(
            '{"overview":1, "shift": 1, "more_detais":1, "gallery":1 , "amenities":0, "add_on":0}'
          );
          var formData = new FormData();
          formData.append("tab_type", tab_type);
          formData.append("tabs", tabs);
          formData.append("child_venue_id", this.chld_venue_id);
          formData.append("edit_status", edit_status);

          if (this.files_day_light.length != 0) {
            for (var i = 0; i < this.files_day_light.length; i++) {
              formData.append("day_arr[]", this.files_day_light[i]);
            }
          }

          if (this.files_night_life.length != 0) {
            for (var a = 0; a < this.files_night_life.length; a++) {
              formData.append("night_arr[]", this.files_night_life[a]);
            }
          }
          if (this.files_front_view.length != 0) {
            for (var n = 0; n < this.files_front_view.length; n++) {
              formData.append("front_arr[]", this.files_front_view[n]);
            }
          }
          if (this.files_back_view.length != 0) {
            for (var b = 0; b < this.files_back_view.length; b++) {
              formData.append("back_arr[]", this.files_back_view[b]);
            }
          }
          axios
            .post("/api/add_child_venue", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              if (resp.data.status_code == 200) {
                if (resp.data.status) {
                    resp.data.status
                } else {
                  this.$root.$refs.app.showToast(
                    "danger",
                    "only .jpg, .png, .jpeg, .svg, .mp4, .mkv file format are supported"
                  );
               
                }
              }
            });
            return 1;
        } 
        else
        {
          return 1;
        }
      }
      else
        {
          return 3;
        }
    },
      }
    }




</script>

<style>

</style>