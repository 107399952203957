<template>
  <div>
    
    <b-form>
							<div class="mb-3">
								<div class="p-2">
									<!--  <div class="text-center">
										<h4 class="">
										<u> Meeting Space </u>
										</h4>
									</div> -->
									
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<h6 class="custom-text"><span>Meeting Space </span></h6>
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Meeting Space<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="metting_space"
														placeholder="Enter meeting space"
														v-model="$v.more_detail.metting_space.$model"
														:state="validateStateMoreD('metting_space')"
														></b-form-input>
														<b-form-invalid-feedback
														id="metting_space"
														v-if="$v.more_detail.metting_space.$error"
														>
															<div
															v-if="!$v.more_detail.metting_space.required"
															>
																Please enter metting space
															</div>
															<div v-if="!$v.more_detail.metting_space.numeric">
																Please enter valid metting space
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<h6 class="custom-text"><span>Room Details  </span></h6>
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Type of room<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-select class="venue-custom-input bac_none"
														id="type_of"
														:options="type_of_data"
														v-model="$v.more_detail.type_of.$model"
														:state="validateStateMoreD('type_of')"
														></b-form-select>
														<b-form-invalid-feedback
														id="type_of"
														v-if="$v.more_detail.type_of.$error"
														>
															<div v-if="!$v.more_detail.type_of.required">
																Please select room type
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
								<div class="p-2">
									<!--  <div class="text-center">
										<h4 class="">
										<u> Room Details </u>
										</h4>
									</div> -->
									
								</div>
								<div class="p-2">
									<!-- <div class="text-center">
										<h4 class="">
										<u> Size of room </u>
										</h4>
									</div> -->
									<h6 class="custom-text"><span>Size of room   </span></h6>
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Room Size<span class="text-danger">*</span> :</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="room_size"
														placeholder="Enter room size"
														v-model="$v.more_detail.room_size.$model"
														:state="validateStateMoreD('room_size')"
														></b-form-input>
														<b-form-invalid-feedback
														id="room_size"
														v-if="$v.more_detail.room_size.$error"
														>
															<div v-if="!$v.more_detail.room_size.required">
																Please enter room size
															</div>
															<div v-if="!$v.more_detail.room_size.numeric">
																Please enter valid room size
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Ceiling Heignt<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="ceiling_heignt"
														placeholder="Enter ceiling heignt"
														v-model="$v.more_detail.ceiling_heignt.$model"
														:state="validateStateMoreD('ceiling_heignt')"
														></b-form-input>
														<b-form-invalid-feedback
														id="ceiling_heignt"
														v-if="$v.more_detail.ceiling_heignt.$error"
														>
															<div
															v-if="!$v.more_detail.ceiling_heignt.required"
															>
																Please enter ceiling heignt
															</div>
															<div
															v-if="!$v.more_detail.ceiling_heignt.numeric"
															>
																Please enter valid ceiling heignt
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Room Dimension<span class="text-danger">*</span>
														:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														id="room_dimension"
														placeholder="Enter room dimension"
														v-model="$v.more_detail.room_dimension.$model"
														:state="validateStateMoreD('room_dimension')"
														></b-form-input>
														<b-form-invalid-feedback
														id="room_dimension"
														v-if="$v.more_detail.room_dimension.$error"
														>
															<div
															v-if="!$v.more_detail.room_dimension.required"
															>
																Please enter room dimension
															</div>
															<div
															v-if="!$v.more_detail.room_dimension.numeric"
															>
																Please enter valid room dimension
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
								<div class="p-2">
									<!-- div class="text-center">
										<h4 class="">
										<u>Set up type</u>
										</h4>
									</div> -->
									<h6 class="custom-text"><span>Set up type  </span></h6>
									<b-row>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														U-shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="u_shape"
														v-model="$v.more_detail.u_shape.$model"
														:state="validateStateMoreD('u_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="u_shape"
														v-if="$v.more_detail.u_shape.$error"
														>
															<div v-if="!$v.more_detail.u_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Banquate Round:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="banquate_round"
														v-model="$v.more_detail.banquate_round.$model"
														:state="validateStateMoreD('banquate_round')"
														></b-form-input>
														<b-form-invalid-feedback
														id="banquate_round"
														v-if="$v.more_detail.banquate_round.$error"
														>
															<div
															v-if="!$v.more_detail.banquate_round.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Cocktail Round:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="cocktail_round"
														v-model="$v.more_detail.cocktail_round.$model"
														:state="validateStateMoreD('cocktail_round')"
														></b-form-input>
														<b-form-invalid-feedback
														id="cocktail_round"
														v-if="$v.more_detail.cocktail_round.$error"
														>
															<div
															v-if="!$v.more_detail.cocktail_round.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Theater:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="theater"
														v-model="$v.more_detail.theater.$model"
														:state="validateStateMoreD('theater')"
														></b-form-input>
														<b-form-invalid-feedback
														id="theater"
														v-if="$v.more_detail.theater.$error"
														>
															<div v-if="!$v.more_detail.theater.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Classroom:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="classroom"
														v-model="$v.more_detail.classroom.$model"
														:state="validateStateMoreD('classroom')"
														></b-form-input>
														<b-form-invalid-feedback
														id="classroom"
														v-if="$v.more_detail.classroom.$error"
														>
															<div v-if="!$v.more_detail.classroom.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Boardroom:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="boardroom"
														v-model="$v.more_detail.boardroom.$model"
														:state="validateStateMoreD('boardroom')"
														></b-form-input>
														<b-form-invalid-feedback
														id="boardroom"
														v-if="$v.more_detail.boardroom.$error"
														>
															<div v-if="!$v.more_detail.boardroom.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														E-shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="e_shape"
														v-model="$v.more_detail.e_shape.$model"
														:state="validateStateMoreD('e_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="e_shape"
														v-if="$v.more_detail.e_shape.$error"
														>
															<div v-if="!$v.more_detail.e_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Hollow square:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="hollow_square"
														v-model="$v.more_detail.hollow_square.$model"
														:state="validateStateMoreD('hollow_square')"
														></b-form-input>
														<b-form-invalid-feedback
														id="hollow_square"
														v-if="$v.more_detail.hollow_square.$error"
														>
															<div v-if="!$v.more_detail.hollow_square.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Perimiter Seating:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="perimiter_seating"
														v-model="$v.more_detail.perimiter_seating.$model"
														:state="validateStateMoreD('perimiter_seating')"
														></b-form-input>
														<b-form-invalid-feedback
														id="perimiter_seating"
														v-if="$v.more_detail.perimiter_seating.$error"
														>
															<div
															v-if="!$v.more_detail.perimiter_seating.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Royal Conference:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="royal_conference"
														v-model="$v.more_detail.royal_conference.$model"
														:state="validateStateMoreD('royal_conference')"
														></b-form-input>
														<b-form-invalid-feedback
														id="royal_conference"
														v-if="$v.more_detail.royal_conference.$error"
														>
															<div
															v-if="!$v.more_detail.royal_conference.numeric"
															>
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														T-Shape:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="t_shape"
														v-model="$v.more_detail.t_shape.$model"
														:state="validateStateMoreD('t_shape')"
														></b-form-input>
														<b-form-invalid-feedback
														id="t_shape"
														v-if="$v.more_detail.t_shape.$error"
														>
															<div v-if="!$v.more_detail.t_shape.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
										<b-col lg="6" md="6" sm="12">
											<b-row class="py-3 align-items-center">
												<b-col sm="4" class="text-left">
													<label class="fw-medium mb-0 mt-2" for="uname2">
														Talk Show:</label
													>
												</b-col>
												<b-col sm="8">
													<b-form-group>
														<b-form-input class="venue-custom-input"
														placeholder="Enter person capacity"
														id="talk_show"
														v-model="$v.more_detail.talk_show.$model"
														:state="validateStateMoreD('talk_show')"
														></b-form-input>
														<b-form-invalid-feedback
														id="talk_show"
														v-if="$v.more_detail.talk_show.$error"
														>
															<div v-if="!$v.more_detail.talk_show.numeric">
																Please enter valid person capacity
															</div>
														</b-form-invalid-feedback>
													</b-form-group>
												</b-col>
											</b-row>
										</b-col>
									</b-row>
								</div>
							</div>
						</b-form>
  </div>
</template>

<script>
import axios from "axios";
	import {
		required,
		
		numeric,
		
	} from "vuelidate/lib/validators";
    import { validationMixin } from "vuelidate";
export default {
    props:['chld_venue_id'],
    mixins: [validationMixin],
    data: () => ({
        type_of_data: [{ value: null, text: "Please select room type" }],
        more_detail: {
    metting_space: "",
    type_of: null,
    room_size: "",
    ceiling_heignt: "",
    u_shape: "",
    banquate_round: "",
    cocktail_round: "",
    theater: "",
    classroom: "",
    boardroom: "",
    e_shape: "",
    hollow_square: "",
    perimiter_seating: "",
    royal_conference: "",
    t_shape: "",
    talk_show: "",
    room_dimension: "",
},
    }),
    validations: {
			
			more_detail: {
				metting_space: {
					required,
					numeric,
				},
				type_of: {
					required,
				},
				room_size: {
					required,
					numeric,
				},
				ceiling_heignt: {
					required,
					numeric,
				},
				room_dimension: {
					required,
					numeric,
				},
				u_shape: {
					numeric,
				},
				banquate_round: {
					numeric,
				},
				cocktail_round: {
					numeric,
				},
				theater: {
					numeric,
				},
				classroom: {
					numeric,
				},
				boardroom: {
					numeric,
				},
				e_shape: {
					numeric,
				},
				hollow_square: {
					numeric,
				},
				perimiter_seating: {
					numeric,
				},
				royal_conference: {
					numeric,
				},
				t_shape: {
					numeric,
				},
				talk_show: {
					numeric,
				},
			},
		},
        methods: {
            validateStateMoreD(metting_space) {
				const { $dirty, $error } = this.$v.more_detail[metting_space];
				return $dirty ? !$error : null;
			},

        next_moredetails() {
			if(this.chld_venue_id)
				{
        this.$v.more_detail.$touch();
        if (!this.$v.more_detail.$anyError) {
          const tab_type = "more_details";
          const tabs = JSON.parse(
            '{"overview":1, "shift": 1, "more_detais":1, "gallery":0, "amenities":0, "add_on":0}'
          );
          axios
            .post("/api/add_child_venue", {
              filled_tab: tabs,
              tab_type: tab_type,
              child_venue_id: this.chld_venue_id,
              setup_type_id: this.more_detail.type_of,
              meeting_space: this.more_detail.metting_space,
              room_size: this.more_detail.room_size,
              ceiling_height: this.more_detail.ceiling_heignt,
              room_dimention: this.more_detail.room_dimension,
              u_shape: this.more_detail.u_shape,
              banquet_round: this.more_detail.banquate_round,
              cocktail_round: this.more_detail.cocktail_round,
              theater: this.more_detail.theater,
              classroom: this.more_detail.classroom,
              boardroom: this.more_detail.boardroom,
              e_shape: this.more_detail.e_shape,
              hollow_square: this.more_detail.hollow_square,
              perimeter_seating: this.more_detail.perimiter_seating,
              royal_conf: this.more_detail.royal_conference,
              t_shape: this.more_detail.t_shape,
              talk_show: this.more_detail.talk_show,
            })
            .then((resp) => {
              if (resp.data.status_code == 200) {
               resp
              }
            });
			
			
		return 1;
				
        }
		else
				{
					return 0;
				}
	}
				
		else
		{
			return 3;
		}
    },

    get_typeofRoom() {
				axios.get("/api/get_setup_type").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for (var i = 0; i < resp.data.setup_type.length; i++) {
								this.type_of_data.push({
									value: resp.data.setup_type[i].id,
									text: resp.data.setup_type[i].type_name,
								});
							}
						}
					}
				});
			},
},
mounted()
{
    this.get_typeofRoom();
}


}
</script>

<style>

</style>

