<template>
	<div>
		<b-col
		class="d-flex align-items-stretch"
		cols="12"
		lg="12"
		md="12"
		sm="12"
		>
			<div class="w-100 d-flex justify-content-end">
				<div class="p-2">
					<b-button size="sm"
					id="button_width1"
					variant="primary"
					@click="CreateAmetc()"
					>
						<i class="mdi mdi-plus"></i>Add New</b-button
					>
				</div>
				
			</div>
		</b-col>
		<div
		v-for="(amenities_detail, index) in amenities_details"
		:key="index"
		>
			<div class="mb-3">
                <div class="border p-2">
					<div class="text-center">
						<h4 class="">
							<u> {{ amenities_detail.category }} </u>
						</h4>
					</div>
					<br />
					<b-row cols="1" cols-sm="1" cols-md="2" cols-lg="4">
						<b-col
						class="d-flex align-items-stretch"
						v-for="(option, index_c) in amenities_detail.amenities"
						:key="index_c"
						>
							<div class="w-100">
								<b-form-checkbox
								@change="add_amenities(option.id)"
								:key="option.id"
								:value="option.id"
								name="flavour-4a"
								inline
								>
									{{ option.name }}
								</b-form-checkbox>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
		<!--  Add Category -->
		<b-modal
		:no-close-on-backdrop="true"
		title="Create Category and add details"
		ok-title="Save"
		@ok="handleOkAddOnCQ"
		id="add_on_amtec"
		>
			<b-form
			id="addon-input-group-1"
			@submit.stop.prevent="handleSubmitAddOnCAme"
			>
				
                <b-form-group label-for="name-group" id="name-group">
					<b-row class="py-3 align-items-center" v-if="!newCategory" >
						
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>Category<span class="text-danger">*</span> :</label
							>
						</b-col>
						
						<b-col sm="8">
							
							<select class="form-control" v-model="amenities.ThCategory">
								<option value="">Select Category</option>
								
								<option v-for="(sc, indx) in amCategory" :value="sc.id" :key="indx">{{ sc.category }}</option>
							</select>
							
						</b-col>
						<b-col sm="1">
							<button class="btn btn-info btn-sm" v-if="!newCategory" @click="selectnew()"><i class="mdi mdi-plus"></i></button>
							
						</b-col>
					</b-row>
					
					
					<b-row class="py-3 align-items-center" v-if="newCategory">
						
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							>New Category<span class="text-danger">*</span> </label
							>
						</b-col>
						
						<b-col sm="8">
							<input type="text" class="form-control" v-model="amenities.NCategory">
						</b-col>
						<b-col sm="1">
							<button class="btn btn-danger btn-sm" v-if="newCategory" @click="selectRemove()"><i class="mdi mdi-delete"></i></button>
							
						</b-col>
						
					</b-row>
					<b-row class="py-3 align-items-center">
						
						<b-col sm="3" class="text-left">
							<label class="fw-medium mb-0" for="lname2"
							> Amenities<span class="text-danger">*</span> :</label
							>
						</b-col>
						
						<b-col sm="9">
							<input type="text" class="form-control" v-model="amenities.new_amenities" placeholder="Enter the Amenities">
						</b-col>
						
					</b-row>
				</b-form-group>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
        props:['chld_venue_id'],
		data: () => ({
			amenities_details: [],
			added_amenities: [],
			
			amCategory:[],
			newCategory:false,
			amenities:{
				ThCategory:"",
				NCategory:"",
				new_amenities:"",
			},
		}),
		methods:
		{
			get_amenities() {
				//
				axios.post("/api/get_amenities").then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.amenities_details = resp.data.amenities_details;
						}
					}
				});
			},
			add_amenities(id) {
				if (this.added_amenities.indexOf(id) !== -1) {
					this.added_amenities.splice(this.added_amenities.indexOf(id), 1);
					} else {
					this.added_amenities.push(id);
				}
			},
			next_amenities() {
				if(this.chld_venue_id)
				{
				const tab_type = "amenities";
				const tabs = JSON.parse(
				'{"overview":1, "shift": 1, "more_detais":1, "gallery":1, "amenities":1, "add_on":0}'
				);
				axios
				.post("/api/add_child_venue", {
					filled_tab: tabs,
					tab_type: tab_type,
					child_venue_id: this.chld_venue_id,
					amenities_arr: this.added_amenities,
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.add_on_Listing();
							
						}
					} 
				});
				return 1;
			}
			else
			{
				return 3;
			}
			},
            CreateAmetc() {
		
        this.$bvModal.show("add_on_amtec");
        this.Getamenities();
    },
    Getamenities()
    {
        axios
        .post("api/listget_amenities",
        { child_id: this.child_venue_id})
        .then((resp) => {
            if (resp.data.status_code == 200) {
                if (resp.data.status) {
                    this.amCategory=resp.data.get_amenities;
                    } else {
                    this.$root.$refs.app.showToast("danger", resp.data.message);
                }
            }
        });
    },
    selectnew()
    {
        this.amenities.ThCategory="";
        //amenities.ThCategory  amenities.NCategory
        this.newCategory=true;
    },
    selectRemove()
    {
        
        this.amenities.NCategory="";
        this.newCategory=false;
    },
    handleOkAddOnCQ() {
		if(this.newCategory!=true)
		{
			if(this.amenities.ThCategory=="")
			{
				this.$root.$refs.app.showToast("danger", 'Please select ');
				return false;
			}
			
		}
		if(this.newCategory==true)
		{
			if(this.amenities.NCategory=="")
			{
				this.$root.$refs.app.showToast("danger", 'Please enter  ');
				return true;
			}
			
		}
		
        axios
        .post("/api/add_amenties_category",this.amenities)
        .then((resp) => {
            if (resp.data.status_code == 200) {
                if (resp.data.status) { 
                    this.$root.$refs.app.showToast("success", 'added');
                    //  this.closeAddOnC();
                    //  this.add_on_Listing();
                    this.amenities.ThCategory="";
                    this.amenities.NCategory="";
                    this.amenities.new_amenities="";

                    this.$bvModal.hide("add_on_amtec");
                    this.get_amenities();
					this.newCategory=false;
                }
            } 
            else
            {
                this.$root.$refs.app.showToast("danger", resp.data.message);
            }
        });
    },
	
		},
		
        mounted() {
    this.get_amenities();
        }
	}
	
	
</script>

<style>
	
</style>